@use '../../../themes/global.scss' as Globals;

.filters {
  width: 100%;
  min-height: 50px;
  padding-left: 8px;
  padding-right: 15px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.innerFilters {
  justify-content: end;
  flex-wrap: wrap;
  row-gap: 14px;
  padding-bottom: 14px;
}

@media screen and (max-width: 768px) {
  .innerFilters {
    zoom: 0.9;
    min-height: 140px;
    max-height: 140px;
  }
}

.add {
  width: 32px;
  height: 32px;
  background-color: Globals.$mainColor;
  border-radius: 5px;

  i {
    width: 32px;
    height: 32px;
    padding-top: 7px;
    font-size: 20px;
    color: Globals.$textInputBaseColor;
    text-align: center;
  }
}

.searchFilters {
  width: 250px;
  height: 32px !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

.selectFilters {
  height: 32px;
  padding-top: 0px;
  padding-left: 8px;
  padding-bottom: 0px;
  margin-left: 32px;
}

@media screen and (max-width: 768px) {
  .filters {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media screen and (max-width: 576px) {
  .searchFilters {
    height: 36px !important;
    font-size: 12px;
  }
  .selectFilters {
    height: 34px;
    font-size: 12px;
  }
  .icon-date-picker {
    font-size: 11px;
    top: 4.5px;
    right: 10px;
  }
  .add i {
    font-size: 18px;
    padding-top: 3px;
  }
}
